'use client';

import { api } from '~/trpc/react';

import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useFilter } from '~/utils/useFilter';
import { useDebounce } from '@uidotdev/usehooks';

import Link from 'next/link';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Dialog, DialogContent, DialogTrigger } from '~/components/ui/dialog';
import { Button } from '~/components/ui/button';
import { Search } from 'lucide-react';
import { Input } from '~/components/ui/input';
import { Typography } from '~/components/ui/typography';
import { Briefcase, Camera, Mic, Lightbulb, Monitor, Settings, Focus, Route, Building2, Box } from 'lucide-react';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { customEncodeURI } from '~/utils/encode-uri';

const searchSchema = z.object({
  searchQuery: z.string().max(255)
});

export default function SearchDialog() {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useFilter();
  const form = useForm<z.infer<typeof searchSchema>>({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      searchQuery: ''
    }
  });
  const searchQuery = form.watch('searchQuery');
  const { data: productSearchResults } = api.product.dialogSearch.useQuery({
    searchQuery: searchQuery,
    region: filter?.region || 'EE'
  });
  const { data: merchantSearchResults } = api.merchant.dialogSearch.useQuery({
    searchQuery: searchQuery,
    region: filter?.region || 'EE'
  });

  const debouncedProductSearchResults = useDebounce(productSearchResults, 300);
  const debouncedMerchantSearchResults = useDebounce(merchantSearchResults, 300);

  const handleNavigate = () => {
    form.setValue('searchQuery', '');
    setIsOpen(false);
  };

  function onSubmit(values: z.infer<typeof searchSchema>) {
    setFilter({ searchTerm: values.searchQuery }, '/search');
    setIsOpen(false);
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <div>
          <Button variant="ghost" size="icon" className="sm:hidden text-gray-500">
            <Search size={20} />
          </Button>
          <Button className="hidden sm:inline-flex items-center whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground px-4 py-2 relative h-8 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-64 mr-3">
            <span className="hidden lg:inline-flex">{t('searchProducts')}...</span>
            <span className="inline-flex lg:hidden">{t('search')}...</span>
            <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
              <Search size={13} />
            </kbd>
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] p-0 gap-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-row items-center justify-between border-b relative">
              <Search size={20} className="text-gray-500 absolute left-4" />
              <FormField
                control={form.control}
                name="searchQuery"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={t('typeToSearch')}
                        className="pl-12 py-6 border-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 flex h-10 w-full rounded-md bg-transparent text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
        <div className="pb-2 pt-2 h-[calc(100vh-200px)] max-h-[360px] overflow-y-auto">
          {searchQuery.length === 0 ? (
            <div className="px-2">
              <Typography className="px-4 " variant="caption">
                {t('Kategooriad')}
              </Typography>
              <div className="flex flex-col">
                <Button
                  onClick={() => {
                    setFilter({ categories: ['95582c22-51f4-4078-a055-4ce3689dc0bd'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Briefcase className="mr-2" size={18} />
                    {t('Komplektid')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['2a2fe4d6-4193-4ed1-9b8c-27be30a8b0b9'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Camera className="mr-2" size={18} />
                    {t('Kaamerad')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['13dd7157-8b1a-427e-84e0-8dfaadfcdb63'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Focus className="mr-2" size={18} />
                    {t('Objektiivid')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['e46a5262-915e-487a-8b02-efc83fdee81f'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Mic className="mr-2" size={18} />
                    {t('Helitehnika')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['18b23e25-b601-4f63-9b97-a0ee734242c3'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Lightbulb className="mr-2" size={18} />
                    {t('Valgustus')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['18992ba2-547b-446f-a5e1-0ea2ce15ee60'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Monitor className="mr-2" size={18} />
                    {t('Monitorid')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['ee393a9a-dbab-49ee-882f-e60e9cefc74f'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Route className="mr-2" size={18} />
                    {t('Stabilisaatorid')}
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ categories: ['20da3811-4fc8-4846-8e0a-c13ceb4a7a47'] }, '/search');
                  }}
                  variant="ghost"
                  className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <Settings className="mr-2" size={18} />
                    {t('Lisavarustus')}
                  </div>
                </Button>
              </div>
            </div>
          ) : (
            <>
              {(debouncedMerchantSearchResults?.length || 0) > 0 && (
                <div className="px-2">
                  <Typography className="px-4 " variant="caption">
                    {t('Rendipunktid')}
                  </Typography>
                  {debouncedMerchantSearchResults?.map((merchant) => (
                    <Link
                      key={merchant.id}
                      href={`/merchant/${customEncodeURI(merchant.handle)}`}
                      onClick={handleNavigate}
                    >
                      <Button
                        onClick={() => {
                          setFilter({ categories: ['20da3811-4fc8-4846-8e0a-c13ceb4a7a47'] }, '/search');
                        }}
                        variant="ghost"
                        className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200 overflow-hidden"
                      >
                        <div className="flex flex-row items-center">
                          <Building2 className="mr-2" size={18} />
                          {merchant.name.length > 45 ? merchant.name.slice(0, 45) + '...' : merchant.name}
                        </div>
                      </Button>
                    </Link>
                  ))}
                </div>
              )}
              {(debouncedProductSearchResults?.length || 0) > 0 && (
                <div className="px-2">
                  <Typography className="px-4 " variant="caption">
                    {t('Tooted')}
                  </Typography>
                  {debouncedProductSearchResults?.map((product) => (
                    <Link
                      key={product.product.id}
                      href={`/listing/${customEncodeURI(product.product.handle)}`}
                      onClick={handleNavigate}
                    >
                      <Button
                        onClick={() => {
                          setFilter({ categories: ['20da3811-4fc8-4846-8e0a-c13ceb4a7a47'] }, '/search');
                        }}
                        variant="ghost"
                        className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200 overflow-hidden"
                      >
                        <div className="flex flex-row items-center">
                          <Box className="mr-2" size={18} />
                          {product.product.name.length > 45
                            ? product.product.name.slice(0, 45) + '...'
                            : product.product.name}
                        </div>
                      </Button>
                    </Link>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
