'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '~/components/ui/sheet';
import { Button } from '~/components/ui/button';
import { MenuIcon, Home, User, Store } from 'lucide-react';
import AdminMenuButton from '~/components/admin/menu/button';

const menuItems = [
  {
    name: 'Koduleht',
    icon: <Home />,
    href: '/'
  }
];

export default function MobileMenu(props: { isLoggedIn: boolean }) {
  const t = useTranslations('');
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      name: t('Koduleht'),
      icon: <Home />,
      href: '/'
    },
    {
      name: props.isLoggedIn ? t('myAccount') : t('log-in'),
      icon: <User />,
      href: '/account'
    },
    {
      name: props.isLoggedIn ? t('shopManager') : t('rentYourEquipment'),
      icon: <Store />,
      href: props.isLoggedIn ? '/admin' : '/getting-started/merchant'
    }
  ];

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button size="icon" variant="ghost" className="text-gray-500">
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="h-100vh flex flex-col p-0 gap-0" hideClose>
        <SheetHeader className="p-6">
          <SheetTitle className="text-left">{t('Menüü')}</SheetTitle>
        </SheetHeader>
        <div className="shrink-0 bg-border h-[1px] w-full" />
        <div className="px-2 pt-4">
          {menuItems.map((menuItem, i) => (
            <div
              key={i}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <AdminMenuButton key={i} name={menuItem.name} icon={menuItem.icon} href={menuItem.href} />
            </div>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}
