'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useLocale } from 'next-intl';

import { Button } from '~/components/ui/button';
import { Globe } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '~/components/ui/dialog';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '~/components/ui/select';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const formSchema = z.object({
  language: z.string(),
  region: z.string()
});

const countries = [
  {
    id: 'EE',
    name: 'Estonia'
  },
  {
    id: 'IT',
    name: 'Italy'
  },
  {
    id: 'FI',
    name: 'Finland'
  }
];

export default function RegionSwitcher() {
  const path = usePathname();
  const router = useRouter();
  const locale = useLocale();

  const [isOpen, setIsOpen] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      language: 'en',
      region: 'ee'
    }
  });

  const region = form.watch('region');
  const language = form.watch('language');
  const currentLanguage = locale.split('-')[0] || 'en';
  const currentRegion = locale.split('-')[1]?.toUpperCase() || 'EE';
  const newLocale = `${language}-${region}`;

  useEffect(() => {
    form.setValue('language', currentLanguage);
    form.setValue('region', currentRegion);
  }, [locale]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsOpen(false);
    const locale = `${values.language}-${values.region}`;
    router.push(`/${locale}`);
    router.refresh();
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" className=" text-gray-500">
          <div className="h-[20px] w-[20px] relative mr-2 shadow-lg rounded-full overflow-hidden">
            <Image
              src={`https://flagcdn.com/${currentRegion.toLowerCase()}.svg`}
              alt={countries.find((country) => country.id === currentRegion)?.name || ''}
              fill
              priority
            />
          </div>
          {countries.find((country) => country.id === currentRegion)?.name}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>Choose your region and language</DialogTitle>
              <DialogDescription>
                To see products from the correct region, please choose your region and language.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="flex flex-col items-center gap-2">
                <FormField
                  control={form.control}
                  name="region"
                  render={({ field }) => (
                    <FormItem className="w-full grid grid-cols-4 gap-4">
                      <FormLabel className="flex items-center justify-end">Region</FormLabel>
                      <div className="col-span-3">
                        <FormControl>
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <SelectTrigger className="w-full col-span-3">
                              <SelectValue placeholder="Select your region" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectLabel>Europe</SelectLabel>
                                {countries.map((country) => (
                                  <SelectItem key={country.id} value={country.id}>
                                    <div className="flex flex-row gap-2">
                                      <Image
                                        src={`https://flagcdn.com/${country.id.toLowerCase()}.svg`}
                                        alt={country.name}
                                        height={20}
                                        width={20}
                                        className="object-contain"
                                      />
                                      {country.name}
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="language"
                  render={({ field }) => (
                    <FormItem className="w-full grid grid-cols-4 gap-4">
                      <FormLabel className="flex items-center justify-end">Language</FormLabel>
                      <div className="col-span-3">
                        <FormControl>
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <SelectTrigger className="w-full col-span-3">
                              <SelectValue placeholder="Select your language" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="en">English</SelectItem>
                                <SelectItem value="et">Estonian</SelectItem>
                                <SelectItem value="it">Italian</SelectItem>
                                <SelectItem value="fi">Finnish</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <DialogFooter className="pt-2">
              <Link href={`/${newLocale}`}>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Save changes
                </Button>
              </Link>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
