/**
 * Encodes a URI component by escaping all characters except
 * alphanumeric and a few special characters. Additionally,
 * replaces periods with a custom string '-dot-'.
 *
 * @param str - The string to be encoded.
 * @returns The encoded string.
 */
export function customEncodeURI(str: string): string {
    return encodeURIComponent(str).replace(/\./g, '-dot-');
}

/**
 * Decodes a custom-encoded string, including those with
 * custom-encoded periods ('-dot-').
 *
 * @param str - The string to be decoded.
 * @returns The decoded string.
 */
export function customDecodeURI(str: string): string {
    return decodeURIComponent(str.replace(/-dot-/g, '.'));
}