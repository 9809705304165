'use client';

import { useTranslations } from 'next-intl';
import { useAtom } from 'jotai';
import { api } from '~/trpc/react';
import { RouterOutputs } from '~/trpc/shared';

import Image from 'next/image';
import Link from 'next/link';
import { cartsAtom, cartOpenAtom } from '~/atoms';

import { Card } from '~/components/ui/card';
import { Button } from '~/components/ui/button';
import { ShoppingCart } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '~/components/ui/sheet';
import { Typography } from '~/components/ui/typography';
import { Trash } from 'lucide-react';
import { toast } from 'sonner';

import { fCurrency } from '~/utils/formatNumber';
import { Skeleton } from '~/components/ui/skeleton';
import CartBlock from './cart-block';

export default function CartDrawer() {
  const [isOpen, setIsOpen] = useAtom(cartOpenAtom);
  const t = useTranslations('');
  const [cartIds, setCartIds] = useAtom(cartsAtom);
  const { data: carts, isLoading } = api.cart.get.useQuery({});

  const isCartEmpty = !carts || carts.length === 0;

  const subTotals = carts?.reduceRight((acc, cart) => acc + cart.cart.subTotal, 0) || 0;
  const discounts = carts?.reduceRight((acc, cart) => acc + cart.cart.discount, 0) || 0;
  const taxes = carts?.reduceRight((acc, cart) => acc + cart.cart.tax, 0) || 0;
  const totals = carts?.reduceRight((acc, cart) => acc + cart.cart.total, 0) || 0;

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <div>
        <Button variant="ghost" className="text-gray-500 hidden sm:flex">
          <ShoppingCart className="mr-3" size={20} />
          {fCurrency(totals)}€
        </Button>
        <Button variant="ghost" size="icon" className="sm:hidden text-gray-500">
          <ShoppingCart size={20}/>
        </Button>
        </div>
      </SheetTrigger>
      <SheetContent className="h-100vh flex flex-col p-0 gap-0" hideClose>
        <SheetHeader className="p-6">
          <SheetTitle>{t('Minu broneering')}</SheetTitle>
        </SheetHeader>
        <div className="shrink-0 bg-border h-[1px] w-full" />
        {isCartEmpty ? (
          <div
            className="flex-auto flex flex-col items-center justify-center w-full gap-2 text-center"
            style={{ height: 'calc(100vh - 150px)' }}
          >
            <Image alt="No listings" src="/images/box.png" width={200} height={200} />
            <Typography variant="h3">{t('Broneering on tühi')}</Typography>
            <Typography variant="caption" className="text-slate-500 max-w-xs pb-4">
              {t('emptyCartDescription')}
            </Typography>
          </div>
        ) : (
          <div className="flex-auto px-6 gap-4 flex-col flex overflow-y-auto py-6">
            {carts?.map((cart) => <CartBlock key={cart.cart.id} cart={cart} />)}
          </div>
        )}

        <div className="shrink-0 bg-border h-[1px] w-full" />
        <div className="pb-6 px-6 pt-3">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center text-gray-500">
              <Typography variant="body" className="font-medium text-xs">
                {t('subtotal')}
              </Typography>
              <Typography variant="body" className=" text-xs">
                {fCurrency(subTotals)}€
              </Typography>
            </div>
            <div className="flex flex-row justify-between items-center text-gray-500">
              <Typography variant="body" className="font-medium  text-xs">
                {t('discount')}
              </Typography>
              <Typography variant="body" className=" text-xs">
                {fCurrency(discounts)}€
              </Typography>
            </div>
            <div className="flex flex-row justify-between items-center">
              <Typography variant="body" className="font-medium text-sm">
                {t('total')}
              </Typography>
              <Typography variant="body" className=" text-sm">
                {fCurrency(totals)}€
              </Typography>
            </div>
          </div>
          <Link href="/checkout" className={isCartEmpty ? 'pointer-events-none' : ''}>
            <Button disabled={isCartEmpty} className="text-sm py-6 px-8 w-full mt-4">
              {t('Soorita broneering')}
            </Button>
          </Link>
        </div>
      </SheetContent>
    </Sheet>
  );
}
