'use client';

import { useLocale } from 'next-intl';
import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useSearchParams } from 'next/navigation';
import { usePathname } from 'next/navigation';
import superjson from 'superjson';

export type Filter = {
  categories: string[];
  dateRange: {
    from: Date | null;
    to: Date | null;
  };
  sort: string;
  location: string;
  page: number;
  region: string;
  searchTerm: string;
};

const defaultLocationMap = [
  { countryCode: 'EE', locationId: '683968b8-ed3a-48cd-aee3-511906ab7c10' },
  { countryCode: 'IT', locationId: '0f323902-df25-4a85-99d9-81394d488bf5' },
  { countryCode: 'FI', locationId: 'b61f84bd-4ef5-4e49-951c-a8f88ae79482' }
];
const getDefaultLocation = (region: string) => {
  if (defaultLocationMap.filter((item) => item.countryCode === region).length > 0) {
    return defaultLocationMap.find((item) => item.countryCode === region)?.locationId;
  } else {
    return '683968b8-ed3a-48cd-aee3-511906ab7c10';
  }
};

export function useFilter() {
  const locale = useLocale();
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const searchQuery: any = !!searchParams.get('query') ? superjson.parse(searchParams.get('query') as string) : {};
  const currentRegion = locale.split('-')[1]?.toUpperCase() || 'EE';

  const filter = useMemo(() => {
    return {
      categories: [],
      dateRange: {
        from: null,
        to: null
      },
      sort: 'default',
      location: getDefaultLocation(currentRegion),
      page: 1,
      region: 'EE',
      searchTerm: '',
      ...searchQuery
    };
  }, [searchParams.get('query') || '']);

  const createQueryString = useCallback(
    (name: string, value: string) => {
      // @ts-ignore
      const params = new URLSearchParams(searchParams);
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const setFilter = (values: any, path?: string) => {
    const page = !!path ? path : pathname;

    router.push(
      page +
        '?' +
        createQueryString(
          'query',
          superjson.stringify({
            ...filter,
            ...values,
            ...((!!values?.categories || !!values?.location || !!values.searchTerm) && { page: 1 })
          })
        )
    );
  };

  return [filter, setFilter];
}
